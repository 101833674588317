import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['taxonPermalinkInput', 'queryInput'];
  static values = { unplugged: Boolean };

  connect() {
    window.addEventListener(
      'search:selectedTaxon',
      (event) => {
        this.taxonPermalinkInputTarget.value = event.detail;
      },
      false,
    );

    if (this.unpluggedValue) {
      this.element.addEventListener(
        'submit',
        (event) => {
          event.preventDefault();

          const nextEvent = new CustomEvent('search:setQuery', {
            detail: {
              query: this.queryInputTarget.value,
              taxonPermalink: this.taxonPermalinkInputTarget.value,
            },
          });
          window.dispatchEvent(nextEvent);
        },
        true,
      );
    }
  }
}
