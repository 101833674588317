import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    window.addEventListener(
      'cart:refreshTotalQuantiy',
      (event) => {
        const newQuantity = parseInt(event.detail);

        this.element.textContent = newQuantity;

        if (newQuantity > 0) {
          this.element.classList.remove('hidden');
          this.element.classList.add('inline');
        } else {
          this.element.classList.remove('inline');
          this.element.classList.add('hidden');
        }
      },
      false,
    );
  }
}
