import { Controller } from 'stimulus';

export default class extends Controller {
  switchTab(event) {
    const element = event.target;
    const tabName = element.dataset['tab'];
    this.showPane(tabName);
    this.updateTabs(element, tabName);
  }

  showPane(tabName) {
    document.querySelectorAll('.tabpanes').forEach((pane) => {
      pane.classList.add('hidden');
      pane.classList.remove('block');
    });
    const tabPane = document.getElementById(`tabpane-${tabName}`);
    tabPane.classList.add('block');
    tabPane.classList.remove('hidden');
    tabPane.classList.remove('last:hidden');
  }

  updateTabs(button, tabName) {
    document.querySelectorAll('.tabs').forEach((tab) => {
      tab.classList.remove('last:bg-white');
      tab.classList.remove('last:text-gray-400');
      tab.classList.add('bg-white');
      tab.classList.remove('bg-gray-diagomics');
      tab.classList.add('text-gray-400');
      tab.classList.remove('text-blue-diagomics-dark');
      tab.querySelector('span').classList.add('hidden');
      tab.querySelector('span').classList.remove('block');
    });
    button.classList.remove('bg-white');
    button.classList.add('bg-gray-diagomics');
    button.classList.remove('text-gray-400');
    button.classList.add('text-blue-diagomics-dark');
    button.querySelector('span').classList.remove('hidden');
    button.querySelector('span').classList.add('block');
  }
}
