import { Controller } from 'stimulus';

export default class extends Controller {
  changeState(event) {
    const countryId = event.target.value;
    const headers = new Headers();
    const stateSelector = event.target.closest('div').nextElementSibling.querySelector('select');
    headers.append('Content-Type', 'application/json');
    fetch(`/api/states?country_id=${countryId}`, headers).then((response) => {
      response.json().then((data) => {
        stateSelector.innerHTML = '';
        stateSelector.innerHTML += '<option value=""></option>';
        data.states.forEach((state) => {
          const option = `<option value="${state.id}">${state.name}</option>`;
          stateSelector.innerHTML += option;
        });
      });
    });
  }

  useBillingAddress(event) {
    const checkboxElem = event.target;
    const shippingBlock = checkboxElem.closest('div').nextElementSibling;
    if (checkboxElem.checked) {
      shippingBlock.classList.add('hidden');
      shippingBlock.classList.remove('grid');
    } else {
      shippingBlock.classList.add('grid');
      shippingBlock.classList.remove('hidden');
    }

    const requiredEls = shippingBlock.querySelectorAll('[data-required-if-visible="true"]');
    requiredEls.forEach((el) => {
      el.required = !el.required;
    });
  }

  useExistingPayment(event) {
    const radioButtonElem = event.target;
    const existingCardsBlock = document.getElementById('existing_cards');
    const paymentBlock = existingCardsBlock.nextElementSibling;
    if (radioButtonElem.value === 'no') {
      paymentBlock.classList.add('block');
      paymentBlock.classList.remove('hidden');
      existingCardsBlock.classList.add('hidden');
      existingCardsBlock.classList.remove('block');
      document.querySelectorAll('.existing-cc-radio').forEach((el) => {
        el.setAttribute('disabled', 'disabled');
      });
    } else {
      paymentBlock.classList.add('hidden');
      paymentBlock.classList.remove('block');
      existingCardsBlock.classList.add('block');
      existingCardsBlock.classList.remove('hidden');
      document.querySelectorAll('.existing-cc-radio').forEach((el) => {
        el.removeAttribute('disabled');
      });
    }
  }

  changePaymentMethod(event) {
    const { value } = event.target;
    document
      .getElementById('payment-methods')
      .querySelectorAll('li')
      .forEach((el) => {
        el.classList.remove('block');
        el.classList.add('hidden');
      });
    document.getElementById(`payment_method_${value}`).classList.remove('hidden');
    document.getElementById(`payment_method_${value}`).classList.add('block');
  }

  enableConfirmSubmit(event) {
    const checkeboxElem = event.target;
    const submitButton = checkeboxElem.closest('.terms_and_conditions').nextElementSibling;
    if (checkeboxElem.checked) {
      submitButton.classList.remove('bg-gray-diagomics-light');
      submitButton.classList.add('bg-blue-500');
      submitButton.removeAttribute('disabled');
    } else {
      submitButton.classList.add('bg-gray-diagomics-light');
      submitButton.classList.remove('bg-blue-500');
      submitButton.setAttribute('disabled', 'disabled');
    }
  }
}
