import { Controller } from 'stimulus';

const TOGGLE_DURATION = 300;

export default class extends Controller {
  static targets = ['pane', 'chevron'];

  initialize() {
    this.selected = null;
  }

  toggle(event) {
    const element = event.target.closest('div[data-index]');
    const nextSelected = parseInt(element.dataset.index);

    this.paneTargets.forEach((pane, index) => {
      if (index !== nextSelected || nextSelected === this.selected) {
        pane.style.maxHeight = '0px';
        this.chevronTargets[index].classList.remove('rotate-180');
      } else {
        pane.style.maxHeight = pane.scrollHeight + 'px';
        this.chevronTargets[index].classList.add('rotate-180');
        this.updateParentPane();
      }
    });
    this.selected = nextSelected === this.selected ? null : nextSelected;
  }

  updateCurrentPane() {
    const pane = this.paneTargets[this.selected];
    if (pane) {
      pane.style.maxHeight = '100%';

      // hack
      setTimeout(() => {
        pane.style.maxHeight = pane.scrollHeight + 'px';
      }, TOGGLE_DURATION);
    }
  }

  updateParentPane() {
    // wait, is it nested inside a accordion?
    const parent = this.element.parentElement.closest(`[data-controller*="accordion"]`);
    const parentController = this.application.getControllerForElementAndIdentifier(
      parent,
      'accordion',
    );
    if (parentController) {
      parentController.updateCurrentPane();
    }
  }
}
