import { Controller } from 'stimulus';

const Y_THRESHOLD = 96;

export default class extends Controller {
  static targets = [
    'header',
    'hamburgerWrapper',
    'openedHamburger',
    'closedHamburger',
    'mobileMenu',
    'desktopMenu',
    'desktopQuickLinks',
    'menuItemPane',
  ];

  connect() {
    // this.toggle() // DEBUG PURPOSE
    this.scrollTicking = false;
    this.lastKnownScrollPosition = window.scrollY;
    // this.toggleDesktopNavigation()
  }

  toggle() {
    this.openedHamburgerTarget.classList.toggle('hidden');
    this.closedHamburgerTarget.classList.toggle('hidden');
    this.mobileMenuTarget.classList.toggle('-translate-x-full');
    this.element.classList.toggle('h-full');
    document.body.classList.toggle('overflow-hidden');
  }

  toggleMenuItemPane(event) {
    this.closeAllSubMenus();
    const selectedPane = event.target.closest('[data-action]').nextElementSibling;
    this.menuItemPaneTargets.forEach((pane) => {
      if (selectedPane !== pane || !!selectedPane.selected) {
        this.hideMenuItemPage(pane);
      } else {
        this.showMenuItemPage(pane);
      }
    });
  }

  closeAllSubMenus() {
    document
      .querySelectorAll('[data-subMenu] > ul:not(.hidden)')
      .forEach((e) => e.classList.toggle('hidden'));
  }

  toggleAllMenuItemPanes(event) {
    var selectedSubMenu = event.target.closest('[data-subMenu]')?.querySelector('ul');
    if (selectedSubMenu) {
      if (event.type == 'mouseover') {
        this.closeAllSubMenus();
        const listNesteadMenus = this.getCascadedMenus(
          event.target.closest('[data-subMenu]').closest('[data-subMenu]'),
        );
        listNesteadMenus.forEach((el) => {
          el.querySelector('ul').classList.remove('hidden');
        });
      }
    }
    if (event.target.tagName == 'A') {
      if (event.type == 'mouseover') {
        const listNesteadMenus = this.getCascadedMenus(
          event.target.closest('[data-subMenu]')?.closest('[data-subMenu]'),
        );
        if (listNesteadMenus) {
          this.closeAllSubMenus();
          listNesteadMenus.forEach((el) => {
            el.querySelector('ul')?.classList?.remove('hidden');
          });
        }
      }
    }
    if (
      event &&
      ((event.type === 'keydown' && event.keyCode !== 27) ||
        this.desktopMenuTarget.contains(event.target))
    ) {
      return;
    }
    this.menuItemPaneTargets.forEach((pane) => this.hideMenuItemPage(pane));
  }

  getCascadedMenus(el) {
    var listEl = [];
    var currentEl = el;
    while (currentEl) {
      listEl.push(currentEl);
      currentEl = currentEl.parentNode.closest('[data-subMenu]');
    }
    return listEl;
  }

  hideMenuItemPage(pane) {
    const button = pane.previousElementSibling;
    button.classList.remove('bg-gray-300');
    pane.classList.add('overflow-hidden');
    pane.style.maxHeight = '0px';
    pane.selected = false;
  }

  showMenuItemPage(pane) {
    const button = pane.previousElementSibling;
    button.classList.add('bg-gray-300');
    pane.classList.remove('overflow-hidden');
    pane.style.maxHeight = pane.scrollHeight + 'px';
    pane.selected = true;
  }

  // NOTE: in case, we don't want the huge header in desktop
  // if we do, just add ```data: { action: 'scroll@window->maglev--header-01#onScroll' }```
  toggleDesktopNavigation() {
    if (this.lastKnownScrollPosition > Y_THRESHOLD) {
      if (this.minimalDesktopNavigation) return; // already set up
      this.desktopMenuTarget.classList.add('md:-translate-y-full');
      this.desktopQuickLinksTarget.classList.add('lg:hidden');
      this.hamburgerWrapperTarget.classList.remove('lg:hidden');
      this.mobileMenuTarget.classList.remove('lg:hidden', 'top-14');
      this.mobileMenuTarget.classList.add('top-18');
      this.mobileMenuTarget.style['height'] = 'calc(100vh - 4rem)';
      this.headerTarget.classList.add('border-b', 'border-gray-200');
      if (this.hideDesktopMenuTargetListener) clearTimeout(this.hideDesktopMenuTargetListener);
      this.hideDesktopMenuTargetListener = setTimeout(
        () => (this.desktopMenuTarget.style.display = 'none'),
        300,
      );
      this.minimalDesktopNavigation = true;
    } else {
      if (!this.minimalDesktopNavigation) return; // already set up
      if (this.hideDesktopMenuTargetListener) clearTimeout(this.hideDesktopMenuTargetListener);
      this.desktopMenuTarget.style.display = '';
      this.desktopMenuTarget.classList.remove('md:-translate-y-full');
      this.desktopQuickLinksTarget.classList.remove('lg:hidden');
      this.hamburgerWrapperTarget.classList.add('lg:hidden');
      this.mobileMenuTarget.classList.add('lg:hidden', 'top-14');
      this.mobileMenuTarget.classList.remove('top-18');
      this.mobileMenuTarget.style['height'] = 'calc(100vh - 3rem)';
      this.headerTarget.classList.remove('border-b', 'border-gray-200');
      this.minimalDesktopNavigation = false;
    }
  }

  onScroll(event) {
    this.lastKnownScrollPosition = window.scrollY;
    if (!this.scrollTicking) {
      window.requestAnimationFrame(() => {
        this.toggleDesktopNavigation();
        this.scrollTicking = false;
      });
      this.scrollTicking = true;
    }
  }
}
