import { Controller } from 'stimulus';
import MarkerImage from '../../images/map-marker.png';

export default class extends Controller {
  static targets = ['map'];

  connect() {
    // google map
    const location = { lat: 43.62808, lng: 1.38011 };
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 4,
      center: location,
      scrollwheel: false,
    });
    this.marker = new google.maps.Marker({
      position: location,
      map: this.map,
      icon: MarkerImage,
    });
  }
}
