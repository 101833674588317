import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hoveredDescription'];

  showHoverText(event) {
    const index = parseInt(event.target.dataset['index']) + 1;
    const text = document.querySelector(`#showcase-01-hover-texts > div:nth-child(${index})`);
    text.classList.remove('hidden');
    text.classList.add('opacity-100');
    text.classList.remove('opacity-0');
    const img = document.querySelector(`#showcase-01-blocks > div:nth-child(${index}) > div > img`);
    img.classList.add('z-10');
  }

  hideHoverText(event) {
    const index = parseInt(event.target.dataset['index']) + 1;
    const text = document.querySelector(`#showcase-01-hover-texts > div:nth-child(${index})`);
    text.classList.add('hidden');
    text.classList.remove('opacity-100');
    text.classList.add('opacity-0');
    const img = document.querySelector(`#showcase-01-blocks > div:nth-child(${index}) > div > img`);
    img.classList.remove('z-10');
  }
}
