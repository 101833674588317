import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['caret', 'question', 'answer'];

  toggle(event) {
    const index = event.target.closest('[data-maglev--faq-01-target="question"]').dataset.index;

    if (this.answerTargets[index].style['max-height'] === '') {
      this.answerTargets[index].style['max-height'] = this.answerTargets[index].scrollHeight + 'px';
      this.caretTargets[index].children[0].innerHTML = '-';
    } else {
      this.answerTargets[index].style['max-height'] = '';
      this.caretTargets[index].children[0].innerHTML = '+';
    }
  }
}
