import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const section = this.element;
    document.addEventListener('turbolinks:before-cache', function () {
      section.querySelector('.g-recaptcha').value = null;
    });
  }
}
