import { Controller } from 'stimulus';

const EDITOR_MAX_WIDTH = 880;

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.maglev-cms-text table').forEach((element) => {
      this.resizeTable(element);
    });
  }

  resizeTable(table) {
    console.log('resizing', table);
    table.querySelectorAll('td').forEach((col) => {
      const widthData = col.dataset['colwidth'];
      if (!widthData) return;
      const widthInPercents = (parseInt(widthData) / EDITOR_MAX_WIDTH) * 100;
      console.log(col, widthInPercents);
      col.style.width = `${widthInPercents}%`;
    });
  }
}
