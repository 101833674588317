import { Controller } from 'stimulus';
import { tns } from 'tiny-slider/src/tiny-slider';

require('tiny-slider/dist/tiny-slider.css');

export default class extends Controller {
  static targets = ['slider', 'nav', 'controls'];

  connect() {
    this.slider = tns({
      container: this.sliderTarget,
      mode: 'carousel',
      mouseDrag: true,
      items: 1,
      center: true,
      slideBy: 'page',
      autoplay: false,
      autoplayTimeout: 6000,
      autoplayButtonOutput: false,
      autoWidth: true,
      arrowKeys: true,
      navContainer: this.navTarget,
      controlsContainer: this.controlsTarget,
    });
  }

  disconnect() {
    this.slider.destroy();
  }
}
