import { Controller } from 'stimulus';

export default class extends Controller {
  deleteItem(event) {
    event.preventDefault();
    const aElem = event.target;
    aElem.closest('tr').querySelector('input').value = 0;
    aElem.closest('form').submit();
  }

  update(event) {
    const button = event.target;
    const form = document.getElementById('update-cart');
    const actionName = button.getAttribute('name');
    const url = form.getAttribute('action');
    form.setAttribute('action', `${url}?${actionName}=`);
    form.submit();
  }
}
