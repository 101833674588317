import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['title', 'caret', 'text'];
  pick(event) {
    const index = event.target.closest('div[data-index]').dataset.index;
    this.unselectAll();
    this.select(index);
  }

  unselectAll() {
    this.titleTargets.forEach((titleTarget, index) => this.unselect(index));
  }

  select(index) {
    this.titleTargets[index].classList.replace(
      'bg-gray-diagomics-light',
      'bg-orange-diagomics-light',
    );
    this.titleTargets[index].classList.replace('text-gray-diagomics-dark', 'text-white');
    this.caretTargets[index].classList.remove('hidden');
    this.textTargets[index].classList.remove('hidden');
  }

  unselect(index) {
    this.titleTargets[index].classList.replace(
      'bg-orange-diagomics-light',
      'bg-gray-diagomics-light',
    );
    this.titleTargets[index].classList.replace('text-white', 'text-gray-diagomics-dark');
    this.caretTargets[index].classList.add('hidden');
    this.textTargets[index].classList.add('hidden');
  }
}
